<template>
  <v-card>
    <v-card-title>Editar Veículo</v-card-title>
    <v-card-text>
      <v-form ref="formUpdateVehicle" @submit.prevent="updateVehicle()">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              label="Placa"
              v-model="editedVehicle.plate"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="KM"
              v-model="editedVehicle.km"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              label="Combustível"
              :items="fuels"
              item-text="name"
              item-value="id"
              v-model="editedVehicle.fuel_id"
              :loading="loadFuels"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" md="2">
            <v-btn
              color="secondary"
              class="ma-2"
              type="submit"
              :loading="saving"
              >Salvar</v-btn
            >
            <v-btn
              color="error"
              class="ma-2"
              @click="$emit('cancelUpdate', true)"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import http from "@/modules/http";
export default {
  mounted() {
    this.getFuels();
    this.editedVehicle = this.vehicle;
  },
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loadFuels: false,
      fuels: [],
      editedVehicle: {},
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  methods: {
    getFuels() {
      this.loadFuels = true;
      http
        .get("api/fuels")
        .then((res) => {
          this.loadFuels = false;
          this.fuels = res.data;
        })
        .catch((err) => {
          this.loadFuels = false;
          console.log(err);
        });
    },
    updateVehicle() {
      if (this.$refs.formUpdateVehicle.validate()) {
        this.saving = true;
        http
          .put("api/vehicles/" + this.vehicle.id, this.editedVehicle)
          .then((res) => {
            this.$side({
              type: "success",
              msg: "Salvo com sucesso aqui",
              duration: 4000,
            });
            this.saving = false;
            this.$emit("vehicleUpdated", true);
            this.$refs.formUpdateVehicle.reset();
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
          });
      }
    },
  },
};
</script>