<template>
  <div>
    <create-vehicle @vehicleSaved="getVehicles()" />
    <h1>Veículos</h1>

    <v-btn
      color="secondary"
      class="mx-1 my-2"
      @click="getVehicles()"
      :loading="loading"
    >
      <v-icon> mdi-refresh </v-icon>
    </v-btn>

    <v-data-table :loading="loading" :items="vehicles" :headers="headers">
      <template v-slot:[`item.fuel_id`]="{ item }">
        <span>{{ item.fuels.name }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="openEditModal(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon
          @click="deleteVehicle(item)"
          color="error"
          :disabled="!deleting ? false : true"
        >
          {{ !deleting ? "mdi-delete" : "mdi-progress-close" }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="editModal">
      <edit-vehicle
        v-if="editModal"
        @cancelUpdate="handleEditVehicle()"
        @vehicleUpdated="handleEditVehicle()"
        :vehicle="clickedVehicle"
      />
    </v-dialog>
  </div>
</template>

<script>
import http from "@/modules/http";
import CreateVehicle from "../components/Create.vue";
import EditVehicle from "../components/Edit.vue";
export default {
  components: {
    CreateVehicle,
    EditVehicle,
  },
  data() {
    return {
      vehicles: [],
      clickedVehicle: {},
      headers: [
        { text: "Placa", value: "plate" },
        { text: "KM", value: "km" },
        { text: "Combustível", value: "fuel_id" },
        { text: "Ações", value: "actions" },
      ],
      loading: false,
      deleting: false,
      editModal: false,
    };
  },
  methods: {
    handleEditVehicle() {
      this.editModal = false;
      this.getVehicles();
    },
    openEditModal(vehicle) {
      this.clickedVehicle = vehicle;
      this.editModal = true;
    },
    deleteVehicle(vehicle) {
      this.$confirm({
        type: "error",
        title: "Remover Veículo",
        content: "Tem certeza que deseja excluir este veículo?",
        confirmText: "Sim",
        cancelText: "Não",
        confirm: () => {
          this.deleting = true;
          http
            .delete("api/vehicles/" + vehicle.id)
            .then((res) => {
              console.log(res);
              this.deleting = false;
              this.$side({
                type: "success",
                msg: "Removido com sucesso",
                duration: 4000,
              });
              this.getVehicles();
            })
            .catch((err) => {
              this.deleting = false;
              console.log(err);
            });
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
        cancel: () => {
          console.log("Your callback");
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
      });
    },
    getVehicles() {
      this.loading = true;
      http
        .get("api/vehicles")
        .then((res) => {
          this.loading = false;
          this.vehicles = res.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.getVehicles();
  },
};
</script>